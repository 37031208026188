<script setup lang="ts">
import type { DialogRootEmits, DialogRootProps } from 'radix-vue';

import { useForwardPropsEmits } from 'radix-vue';

import { Dialog, DialogContent } from '~/components/ui/dialog';

import Command from './Command.vue';

const props = defineProps<DialogRootProps>();
const emits = defineEmits<{ select: [] } & DialogRootEmits>();
const search = defineModel<string>('search', { default: '' });
const forwarded = useForwardPropsEmits(props);
</script>

<template>
  <Dialog v-bind="forwarded" @update:open="(v) => emits('update:open', v)">
    <DialogContent class="overflow-hidden p-0 shadow-lg sm:p-0">
      <Command
        v-model:search-term="search"
        class="[&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5"
        @update:open="() => emits('select')"
      >
        <slot />
      </Command>
    </DialogContent>
  </Dialog>
</template>
