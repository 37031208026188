<template>
  <div>
    <div class="cursor-pointer" @click="handleOpenChange">
      <i class="pi pi-search" />
    </div>
    <CommandDialog
      v-model:open="open"
      v-model:search="search"
      @select="handleOnSelect"
    >
      <CommandInput :placeholder="t('search.searchLotsOrSales')" />
      <CommandList>
        <CommandEmpty>
          {{ $t('search.enterSearch') }}
        </CommandEmpty>
        <CommandItem
          v-if="search"
          :key="search"
          :value="search"
          class="flex items-baseline gap-2"
        >
          <div>{{ $t('search.searchFor') }}</div>
          <div class="font-bold">{{ search }}</div>
        </CommandItem>
      </CommandList>
    </CommandDialog>
  </div>
</template>

<script setup lang="ts">
import {
  CommandDialog,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from '@/components/ui/command';
import { useMagicKeys } from '@vueuse/core';

const open = ref(false);
const search = ref('');
const localePath = useLocalePath();
const { t } = useI18n();

const { Ctrl_K: CtrlK, Meta_K: MetaK } = useMagicKeys({
  onEventFired(e) {
    if (e.key === 'k' && (e.metaKey || e.ctrlKey)) e.preventDefault();
  },
  passive: false,
});

// Listen for Meta+K or Ctrl+K to open the dialog
watch([MetaK, CtrlK], (v) => {
  if (v[0] || v[1]) handleOpenChange();
});

function handleOpenChange() {
  if (!open.value) search.value = '';
  open.value = !open.value;
}

function handleOnSelect() {
  const url = localePath(`/en/search`);
  navigateTo({ path: url, query: { search: search.value } });
  handleOpenChange();
}
</script>
