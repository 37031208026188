<script setup lang="ts">
import type { ComboboxRootEmits, ComboboxRootProps } from 'radix-vue';

import { ComboboxRoot, useForwardPropsEmits } from 'radix-vue';
import { type HTMLAttributes, computed } from 'vue';

import { cn } from '~/utils/tw';

const props = withDefaults(
  defineProps<{ class?: HTMLAttributes['class'] } & ComboboxRootProps>(),
  {
    class: undefined,
    modelValue: '',
    open: true,
  }
);

const emits = defineEmits<ComboboxRootEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <ComboboxRoot
    v-bind="forwarded"
    :class="
      cn(
        'bg-popover text-popover-foreground flex h-full w-full flex-col overflow-hidden rounded-md',
        props.class
      )
    "
  >
    <slot />
  </ComboboxRoot>
</template>
