import { breakpointsTailwind } from '@vueuse/core';

import type { HeaderItem } from '~/types/cms/layout';

type UnknownFunc = () => unknown;

type UseHeaderParams = {
  onClose?: UnknownFunc;
  onOpen?: UnknownFunc;
};

type UseMenuOutput = {
  activeHeaderItem: Ref<HeaderItem | null>;
  close: () => void;
  height: Ref<number>;
  open: (value?: number) => void;
  opened: Ref<boolean>;
};

const opened = ref(false);
const height = ref(0);
const activeHeaderItem = ref(null as HeaderItem | null);
const openEvents: Array<UnknownFunc> = [];
const closeEvents: Array<UnknownFunc> = [];

const scrollLock = useScrollLock(window);
const screenIsMdOrLower =
  useBreakpoints(breakpointsTailwind).smallerOrEqual('md');

/**
 * Opens the top menu with the desired size, triggering the onOpen events.
 * @param [value] The height of the menu you want
 */
function open(value: number = 500) {
  // If the screen is mobile, lock the scroll.
  if (screenIsMdOrLower.value) scrollLock.value = true;
  height.value = value;
  opened.value = true;
  openEvents.forEach((cb) => cb());
}

/**
 * Closes the top menu, triggering the onClose event
 */
function close() {
  opened.value = false;
  scrollLock.value = false;
  height.value = 0;
  closeEvents.forEach((cb) => cb());
}

/**
 * Returns the menu instance, event handlers can be passed as parameters for the opening and closing of the menu
 * @param root0 An object
 * @param root0.onClose Takes a function that will be fired on menu closing
 * @param root0.onOpen Takes a function that will be fired on menu opening
 * @returns The menu instance
 */
export function useMenu({
  onClose,
  onOpen,
}: UseHeaderParams = {}): UseMenuOutput {
  if (onOpen) openEvents.push(onOpen);
  if (onClose) closeEvents.push(onClose);

  return { activeHeaderItem, close, height, open, opened: opened };
}
