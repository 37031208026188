<script setup lang="ts">
import type { ComboboxEmptyProps } from 'radix-vue';

import { ComboboxEmpty } from 'radix-vue';
import { type HTMLAttributes, computed } from 'vue';

import { cn } from '~/utils/tw';

const props = defineProps<
  { class?: HTMLAttributes['class'] } & ComboboxEmptyProps
>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});
</script>

<template>
  <ComboboxEmpty
    v-bind="delegatedProps"
    :class="cn('py-6 text-center text-sm', props.class)"
  >
    <slot />
  </ComboboxEmpty>
</template>
